import React, { useEffect } from 'react'

//
import { BlockFeatureContact, BlockMatchMaker, Layout } from '../components'
import BodySection from '../components/integrations/BodySection/body-section'
import { MicroCopyContext } from '../components/page-wrapper'
import { ButtonNew, Cta, HeroIntegration, Loader } from '../storybook'
import { shuffleArray } from '../utils/common'
import { graphqlFetch } from '../utils/graphql-fetch'
import { getMicroCopy } from '../utils/microcopy'
import { buildPageMetaTags } from '../utils/seo'
import { replaceTokens } from '../utils/text'
import { getUrl } from '../utils/urls'

const query = /* GraphQL */ `
  fragment FragmentTestimonial on Customer {
    sys {
      id
    }
    title
    quote
    slug
    logo {
      url
    }
    customerType
    image {
      url
      height
      width
    }
  }
  fragment FragmentNavigationItem on NavigationItem {
    sys {
      id
    }
    title
    externalUrl
    accessibleText
    icon
    relativePath
    content: contentCollection(limit: 10) {
      items {
        ... on Integration {
          sys {
            id
          }
          title
          slug
          marketsCollection(limit: 20) {
            items {
              title
              slugPrefix
            }
          }
        }
        ... on Page {
          sys {
            id
          }
          title
          slug
          markets: enabledMarketsCollection(limit: 20) {
            items {
              title
              slugPrefix
            }
          }
        }
      }
    }
  }

  query (
    $contentfulId: String!
    $locale: String!
    $customer1Id: String!
    $customer2Id: String!
    $customer3Id: String!
  ) {
    testimonial1: customer(id: $customer1Id, locale: $locale) {
      ...FragmentTestimonial
    }
    testimonial2: customer(id: $customer2Id, locale: $locale) {
      ...FragmentTestimonial
    }
    testimonial3: customer(id: $customer3Id, locale: $locale) {
      ...FragmentTestimonial
    }
    integration(id: $contentfulId, locale: $locale) {
      title
      sys {
        id
      }
      categoriesCollection(limit: 10) {
        items {
          sys {
            id
          }
          title
          slug
          type
          icon
        }
      }
      slug
      body {
        json
        links {
          assets {
            block {
              sys {
                id
              }
              url
              title
              width
              height
              url
            }
          }
          entries {
            hyperlink {
              __typename
              ... on Page {
                sys {
                  id
                }
                slug
              }
              ... on Integration {
                sys {
                  id
                }
                slug
              }
              ... on Customer {
                sys {
                  id
                }
                slug
              }
              ... on Article {
                sys {
                  id
                }
                slug
                categoriesCollection(limit: 1) {
                  items {
                    sys {
                      id
                    }
                    slug
                  }
                }
              }
            }
          }
        }
      }
      whatIsDescription {
        json
      }
      website
      marketsCollection(limit: 20) {
        items {
          sys {
            id
          }
          countryIsoCode
          title
          slugPrefix
          svgFlag {
            url
          }
        }
      }
      matchMaker {
        preface
        title
        matchedPreface
        matchedTitle
        action {
          ...FragmentNavigationItem
        }
      }
    }
  }
`

const Integration = ({ pageContext }) => {
  const {
    contentfulId,
    categories,
    title,
    detailTitle,
    description,
    logo,
    locale,
    langSlugs,
    allMarkets,
    marketsSlugs,
    settings,
    metaImage,
    metaTags,
    marketsMetaTags,
    siteMetadata,
    market,
    allLangSlugs,
    customer: customers
  } = pageContext

  // Get customers associated with current integration
  // and that are enabled in current market
  const customersThatMatchCurrentMarket = customers?.filter((customer) =>
    customer?.markets?.some((thisMarket) => thisMarket.slugPrefix === market.slugPrefix)
  )

  const microCopyData = React.useContext(MicroCopyContext)

  const { slugPrefix, footer } = market

  const { requestADemoPage, integrationDetailRequestDemoBlock } = settings

  const requestDemoSlug = getUrl(
    {
      relativePath: `/${requestADemoPage.slug}`,
      sendUtmParams: true
    },
    slugPrefix
  )

  const pageData = { metaTags, metaImage, pageType: 'integration', marketsMetaTags }

  const pageMetaTags = buildPageMetaTags(pageData, siteMetadata)

  const [isLoading, setIsLoading] = React.useState(true)
  const [templateData, setTemplateData] = React.useState({})

  useEffect(() => {
    // If no customers enabled for current market are found
    // use customers associated with current integration in any market
    const customersToUse =
      Array.isArray(customersThatMatchCurrentMarket) && customersThatMatchCurrentMarket.length > 0
        ? shuffleArray(
            Array.isArray(customersThatMatchCurrentMarket) ? customersThatMatchCurrentMarket : []
          )?.slice(0, 3) // get random 3
        : shuffleArray(Array.isArray(customers) ? customers : [])?.slice(0, 3) // get random 3

    graphqlFetch(query, {
      contentfulId,
      locale,
      customer1Id: customersToUse?.[0]?.id || '',
      customer2Id: customersToUse?.[1]?.id || '',
      customer3Id: customersToUse?.[2]?.id || ''
    })
      .then((res) => {
        setTemplateData({
          integration: res?.data?.integration,
          testimonials:
            res?.data?.testimonial1 || res?.data?.testimonial2 || res?.data?.testimonial3
              ? [res?.data?.testimonial1, res?.data?.testimonial2, res?.data?.testimonial3].filter(
                  (item) => item
                )
              : []
        })
        setIsLoading(false)
      })
      .catch((e) => {
        console.error(e)
      })
  }, [])

  const { integration } = templateData

  const category = categories ? categories[0] : ''

  const typeLabel = category
    ? {
        label: category.title,
        url: `/${slugPrefix}/integrations/${category.slug}`,
        icon: category.icon || ''
      }
    : {}

  return (
    <Layout
      metaTags={pageMetaTags}
      marketsSlugs={marketsSlugs}
      allMarkets={allMarkets}
      langSlugs={langSlugs}
      allLangSlugs={allLangSlugs}
      header={market.header}
      footerMainNavigation={footer?.mainNavigation}
      footerSocialMedia={footer?.socialMedia}
      footerLegalNavigation={footer?.legalNavigation}
      footerActions={footer?.actions}
      marketPhoneNo={market.phoneNumber}
      marketPhoneNo2={market.phoneNumber2}
    >
      <HeroIntegration
        title={detailTitle || title}
        type={typeLabel}
        integrations={[{ id: `logo-${contentfulId}`, label: title, image: logo }]}
        content={description || ''}
        textTokens={{ integration: title }}
        primaryButton={{
          url: requestDemoSlug,
          label: getMicroCopy({ key: 'integration.requestDemo', data: microCopyData })
        }}
      />

      {!isLoading ? (
        <BodySection
          integration={integration}
          requestDemoSlug={requestDemoSlug}
          slugPrefix={slugPrefix}
        />
      ) : (
        <section className="l-section">
          <div className="l-container:12/12 l-container--default-spacing">
            <Loader />
          </div>
        </section>
      )}

      {!isLoading && Array.isArray(category.uspBlocks) && category.uspBlocks.length > 0 ? (
        <BlockFeatureContact data={{ featureCards: category.uspBlocks }} />
      ) : null}

      {integration?.matchMaker && (
        <BlockMatchMaker
          data={{
            extra: { slugPrefix },
            preselected: integration.sys.id,
            ...integration.matchMaker
          }}
        />
      )}
      <Cta
        title={replaceTokens(integrationDetailRequestDemoBlock?.title || '', {
          integration: integration?.title
        })}
        text={integrationDetailRequestDemoBlock?.description || ''}
        classes={['u-display:none@to:viewport-9']}
        anchor={integrationDetailRequestDemoBlock?.anchor}
      >
        {integrationDetailRequestDemoBlock?.action && (
          <ButtonNew url={getUrl(integrationDetailRequestDemoBlock.action, slugPrefix)}>
            {integrationDetailRequestDemoBlock.action.title || ''}
          </ButtonNew>
        )}
      </Cta>
    </Layout>
  )
}

export default Integration
