import React from 'react'

import { MicroCopyContext, SiteDataContext } from '../../../components/page-wrapper'
import { ButtonNew, Category, IconNew, SectionHeader } from '../../../storybook'
import { BUTTON_VARIANT } from '../../../storybook/ButtonNew/ButtonNew'
import { filterTypes } from '../../../storybook/Filter'
import { getMicroCopy } from '../../../utils/microcopy'
//
import RichText from '../../rich-text'

const BodySection = ({ integration, slugPrefix, requestDemoSlug }) => {
  if (!integration) {
    return null
  }

  const siteData = React.useContext(SiteDataContext)
  const microCopyData = React.useContext(MicroCopyContext)

  const { pages } = siteData.settings
  const { integrationsOverview } = pages
  const integrationsSlug = integrationsOverview?.slug || 'integrations'

  const sidebar = (
    <Category
      filters={[
        {
          id: 'integration-filter-category',
          type: filterTypes.tags,
          title: getMicroCopy({ key: 'integration.category', data: microCopyData }),
          showMoreLabel: getMicroCopy({ key: 'integration.showMore', data: microCopyData }),
          showLessLabel: getMicroCopy({ key: 'integration.showLess', data: microCopyData }),
          items: integration?.categoriesCollection?.items.map((cat) => ({
            id: cat.sys.id,
            label: cat.title,
            url: `/${slugPrefix}/${integrationsSlug}/${cat.slug}`.replace('//', '/')
          }))
        },
        {
          id: 'integration-filter-countries',
          type: filterTypes.countries,
          title: getMicroCopy({ key: 'integration.supportedIn', data: microCopyData }),
          showMoreLabel: getMicroCopy({ key: 'integration.showMore', data: microCopyData }),
          showLessLabel: getMicroCopy({ key: 'integration.showLess', data: microCopyData }),
          items: integration?.marketsCollection?.items
            // .filter((market) => market.title !== 'Global' || market.countryIsoCode !== 'XX')
            .map((market) => ({
              id: `market-${market?.sys.id}`,
              label: getMicroCopy({
                key: `integration.market.${market?.countryIsoCode.toLocaleLowerCase()}`,
                data: microCopyData
              }),
              img: market?.svgFlag?.url,
              url: `/${slugPrefix}/${integrationsSlug}#${market?.countryIsoCode}`.replace(
                /\/\//g,
                '/'
              ),
              code: market?.countryIsoCode
            }))
            .sort((a, b) => (a.label > b.label || a.code === 'XX' ? 1 : b.label > a.label ? -1 : 0))
        }
      ]}
      summary={{
        title: getMicroCopy({ key: 'integration.additionalInfo', data: microCopyData }),
        headingType: 'h5',
        inverted: true
      }}
    >
      <div className="c-category__actions">
        <ButtonNew text="button" url={requestDemoSlug} variant={BUTTON_VARIANT.GREEN} fullWidth>
          {getMicroCopy({ key: 'integration.requestDemo', data: microCopyData })}
          <IconNew name="tabler-arrow-narrow-right" />
        </ButtonNew>

        {integration.website ? (
          <ButtonNew
            text="button"
            url={integration?.website}
            variant={BUTTON_VARIANT.SECONDARY}
            fullWidth
          >
            {getMicroCopy({ key: 'integration.website', data: microCopyData })}
            <IconNew name="tabler-arrow-narrow-right" />
          </ButtonNew>
        ) : null}
      </div>
    </Category>
  )

  return (
    <section className="c-body-section l-section l-section-small l-section--white l-section--no-padding-bottom@to:viewport-9">
      <div className="l-container:12/12 l-container--default-spacing u-display:none@to:viewport-9">
        <div className="l-grid l-grid--default-spacing">
          <div className="l-grid__col:6/12@at:viewport-9">
            <SectionHeader
              headingTyp={'h2'}
              title={getMicroCopy({
                key: 'integration.bodyTitle',
                data: microCopyData,
                tokens: { integration: integration.title || '{integration}' }
              })}
              eyebrow={getMicroCopy({
                key: 'integration.bodyPreface',
                data: microCopyData,
                tokens: { integration: integration.title || '{integration}' }
              })}
            />
            <RichText
              data={integration?.body}
              assets={integration?.body?.links?.assets?.block}
              entriesLinks={integration?.body?.links?.entries?.hyperlink}
              classes="s-wysiwyg-editor"
            />
          </div>
          <div className="l-grid__col:6/12@at:viewport-9 l-grid__col:6/12@at:viewport-12">
            {sidebar}
          </div>
        </div>
      </div>

      <div className="u-display:none@at:viewport-9">
        <div className="l-container:12/12 l-container--default-spacing">
          <SectionHeader
            headingTyp={'h2'}
            title={getMicroCopy({
              key: 'integration.bodyTitle',
              data: microCopyData,
              tokens: { integration: integration.title || '{integration}' }
            })}
            eyebrow={getMicroCopy({
              key: 'integration.bodyPreface',
              data: microCopyData,
              tokens: { integration: integration.title || '{integration}' }
            })}
          />
          <RichText data={integration?.body} classes="s-wysiwyg-editor" />
        </div>

        <div className="u-margin-top:64">{sidebar}</div>
      </div>
    </section>
  )
}

export default BodySection
